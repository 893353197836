import { IntegrationAPI } from '@/services/axios'

export default {
	Init(request, config) {
		return IntegrationAPI.post('/api/users/init', request, config)
	},
	Login(data, config) {
		return IntegrationAPI.post('/api/users/login', data, config)
	},
	Me() {
		return IntegrationAPI.post('/api/users/me')
	},
	Logout() {
		return IntegrationAPI.post('/api/users/logout')
	},
	Signup(request, config) {
		return IntegrationAPI.post('/api/users/signup', request, config)
	},
	Upload(body) {
		return IntegrationAPI.post('/api/users/upload', body)
	},
}
