import React, { useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType)

export default function Upload(props) {
	const [files, setFiles] = useState()

	function setFile(files) {
		if (files.length > 0) {
			if (files[0].file) {
				if (props.acceptedFileTypes.includes(files[0].file.type)) {
					setFiles(files)
					props.setFile(files[0].file)
				}
			}
		} else {
			props.setFile(null)
		}
	}

	return (
		<>
			<FilePond
				allowRemove={true}
				files={files}
				onupdatefiles={setFile}
				allowMultiple={false}
				name="files"
				acceptedFileTypes={props.acceptedFileTypes}
				labelIdle='Drag & Drop your file or <span className="filepond--label-action">Browse</span>'
				credits={false}
			/>
		</>
	)
}
