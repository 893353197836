import { LoginContext } from '@/context/login'
import { useContext, useState } from 'react'
import Input from '@/components/ui/Input'
import Account from '@/components/ui/Account'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope, faSackDollar, faWallet } from '@fortawesome/pro-duotone-svg-icons'
import { faFingerprint as fasFingerprint } from '@fortawesome/pro-solid-svg-icons'
import { faLandmark, faUser } from '@fortawesome/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import AuthCode from '@/components/auth/AuthCode'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ListSelector from '../ui/ListSelector'
import CountrySelector from '@/components/ui/CountrySelector'
import Upload from '@/components/ui/Upload'

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [accountType, setAccountType] = useState('INDIVIDUAL')
	const [error, setError] = useState(null)
	const [currency, setCurrency] = useState('')
	const [token, setToken] = useState('')
	const [agree, setAgree] = useState(false)
	const [country, setCountry] = useState('')
	const [countryCode, setCountryCode] = useState('')
	const [amount, setAmount] = useState(0)
	const [filename, setFilename] = useState('')
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()

	const onSubmit = async (data) => {
		setError(null)
		setLoading(true)


		if (amount > 1000000) {
			setAmount(1000000)
		}

		if (accountType !== 'CORPORATE' && agree == false) {
			setLoading(false)
			setError('Please agree the terms')
			return
		}

		if (amount === 0) {
			setLoading(false)
			setError('Please enter an amount')
			return
		}

		if (currency === '') {
			setLoading(false)
			setError('Please select a currency')
			return
		}

		if (token === '') {
			setLoading(false)
			setError('Please select a token')
			return
		}

		if (country === '') {
			setLoading(false)
			setError('Please select a country')
			return
		}


		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		const body = {
			email: data.email,
			type: accountType,
			wallet: data.wallet,
			currency: currency,
			token: token,
			amount: amount,
			country: countryCode,
			document: filename,
		}

		console.log(body)

		await AuthService.Signup(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
							account_type: accountType,
						},
						currentComponent: <AuthCode></AuthCode>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
		wallet: {
			required: 'Wallet required',
		},
	}

	function UpdateCountry(value) {
		setCountry(value.name)
		setCountryCode(value.alpha3)
	}

	async function UploadFile(file) {
		if (file === null) {
			return
		}
		setError(null)
		setLoading(true)

		let bodyFormData = new FormData()
		bodyFormData.append('document', file)
		await AuthService.Upload(
			bodyFormData
		)
			.then(async (data) => {
				setFilename(data.filename)
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
	}

	return (
		<>
			{error ? <Alert type='error' title={error}></Alert> : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex justify-between mb-4">
					<div className="w-1/2 pr-2">
						<Account
							full
							value={accountType}
							account='INDIVIDUAL'
							icon={faUser}
							label='Individual'
							onClick={() => setAccountType('INDIVIDUAL')}
						/>
					</div>
					<div className="w-1/2 pl-2">
						<Account
							full
							onClick={() => setAccountType('CORPORATE')}
							icon={faLandmark}
							account='CORPORATE'
							value={accountType}
							label='Corporate'
						/>
					</div>
				</div>
				<div className='mb-5'>
					<Input
						icon={faEnvelope}
						label='Email'
						referrer={register(formSchema.email)}
						placeholder='john.doe@synaps.io'
						name='email'
						error={errors.email}
						message={errors.email?.message}
					/>
				</div>
				<div className='mb-5'>
					<Input
						icon={faWallet}
						label='ETH Wallet'
						referrer={register(formSchema.wallet)}
						placeholder='0xXXXXX'
						name='wallet'
						error={errors.wallet}
						message={errors.wallet?.message}
					/>
				</div>
				<div className='mb-5'>
					<label
						className="block text-sm font-medium text-gray-700 mb-2">
						Token of participation
					</label>

					<ListSelector
						onSelect={(selection) => setToken(selection)}
						selected={token}
						choices={[
							{
								label: 'SDAO',
								value: 'SDAO',
							},
							{
								label: 'AGIX',
								value: 'AGIX',
							}
						]}></ListSelector>
				</div>
				<div className='mb-5'>
					<Input
						icon={faSackDollar}
						label='Amount to be contributed (in USDT equivalent)'
						placeholder='100'
						name='amount'
						value={amount}
						setValue={(value) => setAmount(value)}
					/>
				</div>
				<div className='mb-5'>
					<label
						className="block text-sm font-medium text-gray-700 mb-2">
						Currency of contribution
					</label>

					<ListSelector
						onSelect={(selection) => setCurrency(selection)}
						selected={currency}
						choices={[
							{
								label: 'USDT',
								value: 'USDT',
							},
							{
								label: 'WETH',
								value: 'WETH',
							}
						]}></ListSelector>
				</div>
				<div className="mb-5">
					<label
						className="block text-sm font-medium text-gray-700 mb-2">
						Country of residence
					</label>
					<CountrySelector placeholder="country name" value={country} setValue={(value) => UpdateCountry(value)} />
				</div>
				{countryCode === 'GBR' ? <>
					<div className="mb-5">
						<label
							className="block text-sm font-medium text-gray-700 mb-2">
							<a className="download-link" href="https://storage.googleapis.com/nunet/UK%20-%20Self-Accredited%20Sophisticated%20%20Investor_NuNet.pdf" target="blank" download>UK sophisticated forms</a> or <a className="download-link" href="https://storage.googleapis.com/nunet/UK%20-%20Self-Accredited%20High%20Net%20Worth%20Investor_NuNet.pdf" target="blank" download>UK high worth investors forms</a>
						</label>
						<Upload
							label={
								 `	<div class="flex">
														<div>
															<img class="mx-auto w-14 mt-1.5 mr-3" src="/img/icons/ui/icon-front-side.svg"/>
														</div>
														<div>
															<p class="text-left no-underline font-medium upload-label rounded-md filepond--label-action">Upload</p>
															<p class="fs-12 text-left">or drag and drop</p>
														</div>
													</div>`
							}
							loading={false}
							allowImagePreview={false}
							imagePreviewHeight={225}
							acceptedFileTypes={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']}
							setFile={(file) => UploadFile(file)}></Upload>
					</div>
				</> : null}
				{accountType !== 'CORPORATE' ? <>
					<div className='mb-5'>
						<label
							className="block text-sm font-medium text-gray-700 mb-2">
							Terms
						</label>
						<div className="flex">
							<input className="checkbox mr-3 mt-1"
								name="isGoing"
								type="checkbox"
								checked={agree}
								onChange={() => setAgree(!agree)} />
							<p>I understand that for eligibility to participate in this community token distribution I am required to hold either 2500 AGIX or 2000 SDAO tokens.</p>

						</div>
					</div>
				</> : null}
				<div>
					<Button
						type='submit'
						full
						loading={loading}
						label='Sign Up'
					/>
				</div>
			</form>
		</>
	)
}
